import React from "react";
import PropTypes from "prop-types";

import Icon from "../custom-widgets/icon";
import ButtonHoverIcon from "./button-hover-icon";
import ChatButton from "./chat-button";
import useToggleMinimizeChat from "../../hooks/use-toggle-minimize-chat";

/**
 * @typedef {Object} ButtonProps
 * @property {string} id - Required string id for the button.
 * @property {string} text - Required string text for the button.
 * @property {string} icon - Required path of the icon at the contact button.
 * @property {string} iconHover - Required path for the hover version of the icon at the contact button.
 * @property {string} url - Optional string path for the anchor element href.
 */

/**
 * @typedef {Object} CustomerServiceButtonProps
 * @property {string} className - Css classes for the button.
 * @property {ButtonProps} btnProps - Required object with the data for the button.
 */

/**
 * This is the component to render the chat section and contact buttons at contact us page.
 *
 * @param {Object} props - The object containing props.
 * @param {Object} props.chatSectionData - required object with the details to show in the section.
 * @param {string} props.chatSectionData.title - required string for the h2 title at green section.
 * @param {string} props.chatSectionData.description - required string for the text description at green section.
 * @param {string} props.chatSectionData.chatWaltButtonText - required string for the button text to chat with Walt.
 * @param {string} props.chatSectionData.chatWaltButtonId - required id string for the button to chat with Walt.
 * @param {CustomerServiceButtonProps[]} props.chatSectionData.customerServiceButtons - required array of objects with the data for the contact options buttons.
 * @param {React.Node} props.children - required react component to render below the buttons and chat section.
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const ChatSection = ({ chatSectionData, children }) => {
  const { title, description, chatWaltButtonText, chatWaltButtonId, customerServiceButtons } = chatSectionData;
  const chatWaltButtonData = {
    className: "btn-link",
    icon: "../../images/icons/chat-walt.svg",
    iconHover: "../../images/icons/chat-walt-hover.svg"
  };

  const { toggleChat, chatLoading } = useToggleMinimizeChat();

  return (
    <section className="container pt-2">
      <div className="row">
        <div className="col col-lg-6 mb-4">
          <div className="border-radius-12 bg-green-10 px-4 pt-3 pb-4 h-100">
            <h2 className="text-green-60 font-weight-semi-bold">
              <Icon class="mr-2" lib="fas" name="comment-smile" />
              <span className="ml-1">{title}</span>
            </h2>
            <p className="font-weight-semi-bold">{description}</p>
            <ButtonHoverIcon
              id={chatWaltButtonId}
              type="button"
              onClick={toggleChat}
              chatLoading={chatLoading}
              text={chatWaltButtonText}
              {...chatWaltButtonData}
            />
          </div>
        </div>
        <div className="col col-lg-6 mb-4">
          {customerServiceButtons.map((btn) => (
            <ChatButton {...btn} />
          ))}
        </div>
      </div>
      {children}
    </section>
  );
};

export default ChatSection;

ChatSection.propTypes = {
  chatSectionData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    chatWaltButtonText: PropTypes.string.isRequired,
    chatWaltButtonId: PropTypes.string.isRequired,
    customerServiceButtons: PropTypes.arrayOf(
      PropTypes.shape({
        className: PropTypes.string,
        btnProps: PropTypes.shape({
          id: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
          icon: PropTypes.string.isRequired,
          iconHover: PropTypes.string.isRequired,
          url: PropTypes.string
        })
      })
    )
  }),
  children: PropTypes.node.isRequired
};
